import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { services } from "src/services";

export type PreviewPosition = "right" | "below";

@customElement('content-editor')
export class ContentEditor extends LitElement {
  static styles = [
    css`
        :host {
          display: contents;
        }

        .content-editor {
          box-sizing: border-box;
          width: 100%;
          padding: 0;
        }

        .component {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            border: 0.5px solid #d1d5db;
            padding: 8px;
        }

        .help-icon {
          color: #111827;
        }

        .help-icon:hover {
          cursor: pointer;
          color: #0069d9;
        }

        .help-icon-container {
          flex-grow: 1;
          padding-top: 4px;
          text-align: right;
        }

        textarea, .preview {
            min-height: 150px;
        }

        textarea {
          margin-bottom: -2px;
        }

        .preview {
          margin-bottom: 4px;
        }

        .preview-below {
          display: block;
        }

        .preview-right {
          display: grid;
          grid-template-columns: auto auto;
          gap: 20px;
        }

        .button-toolbar {
          margin-top: 20px;
        }

        select {
            border: 0.5px solid #d1d5db;
            padding: 4px;
        }

        .slot {
          width: 100%;
          padding: 0;
        }

        .toolbar {
            align-items: center;
            background-color: #f9fafb;
            display: flex;
            margin-bottom: 4px;
        }
    `
  ];

  static formAssociated = true;

  @property({ type: Boolean }) readonly: boolean = false;
  @property({ type: Boolean }) showToolbar: boolean = true;
  @property({ type: Boolean }) showPreview: boolean = true;
  @property({ type: String }) previewPosition: string = "bottom";

  @state() cursorPosition: number;
  @state() htmlContent: string = "";

  @query("#editor") editor: HTMLTextAreaElement;

  private internals: ElementInternals;
  private _value: string = '';

  private contentChangeHandler = async (event: InputEvent) => {
    const target = event.target as HTMLTextAreaElement;
    this.value = target.value;

    this.setHtmlContent(target.value);
    this.setCursorPosition();
  }

  private editorClickHandler = async () => {
    this.setCursorPosition();
  }

  private headingSelectChangeHandler = async (event: InputEvent) => {
    const target = event.target as HTMLTextAreaElement;

    this.insertHeading(target.value);
  }

  private insertHeading(headingType: string) {
    const numberOfHashes = Number(headingType.replace("Heading ", ""));

    let rawContentHead = this.value.substring(0, this.cursorPosition);
    let rawContentTail = this.value.substring(this.cursorPosition, this.value.length);
    let heading = `${'#'.repeat(numberOfHashes)} `;
    
    this.value = `${ rawContentHead }${ heading }${ rawContentTail }`;
    this.editor.value = this.value;

    this.cursorPosition = rawContentHead.length + heading.length;
    this.editor.focus();
    this.editor.setSelectionRange(this.cursorPosition, this.cursorPosition);

    this.setCursorPosition();
  }

  private setCursorPosition() {
    this.cursorPosition = this.editor.selectionStart;
  }

  private setHtmlContent(content: string) {
    if (content) {
      services.management.markdown.preview(content).then(response => {
        this.htmlContent = response.markup;
      });
    }
  }

  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;
    this.internals.setFormValue(newValue);
    this.requestUpdate();
  }

  constructor() {
    super();
    this.internals = this.attachInternals();
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.hasAttribute('value')) {
      this.value = this.getAttribute('value') || '';
    }
    this.setHtmlContent(this.value);
  }

  render() {
    return html`<div class="content-editor">
        ${this.renderToolbar()}
        ${ this.renderEditorAndPreview() }
    </div>`;
  }

  private renderEditorAndPreview() {
    const classes = { 'preview-below': this.previewPosition === 'below', 'preview-right': this.previewPosition === 'right' };
    return html`<div class=${classMap(classes)}>
      ${ this.renderEditor() }
      ${ this.renderBelowEditorSlot() }
      ${ this.renderPreview() }
    </div>`;
  }

  renderEditor() {
    return html`<textarea ?readonly=${this.readonly} id="editor" class="component" .value=${this.value} @click=${this.editorClickHandler} @input=${this.contentChangeHandler}></textarea>`;
  }

  renderBelowEditorSlot() {
    return html`<slot name="below-editor" class="slot"></slot>`;
  }

  renderToolbar() {
    return this.showToolbar && !this.readonly 
    ? 
    html`<div class="component toolbar">
        ${this.renderHeadingSelect()}
        ${this.renderHelpIcon()}
    </div>` 
    : 
    nothing;
  }

  renderHeadingSelect() {
    return html`<select @change=${this.headingSelectChangeHandler}>
        <option>Paragraph</option>
        <option>Heading 1</option>
        <option>Heading 2</option>
        <option>Heading 3</option>
        <option>Heading 4</option>
        <option>Heading 5</option>
        <option>Heading 6</option>
    </select>`;
  }

  renderHelpIcon() {
    return html`<div class="help-icon-container">
        <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">
          <mwc-icon class="help-icon">help</mwc-icon>
        </a>
      </div>`;
  }

  renderPreview() {
    return this.showPreview && this.htmlContent ? html`<div class="component preview">
        ${unsafeHTML(this.htmlContent)}
    </div>` : nothing;
  }
}